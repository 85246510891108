import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { isMobile, isIOS, BrowserView, MobileView } from "react-device-detect";
import  { ReactStoreBadges } from "./ReactStoreBadges"
export const Header = () => {
  const iosLink = "https://apps.apple.com/ee/app/athlon/id1490580477";
  const androidLink = "https://play.google.com/store/apps/details?id=chat.athlon";

  const onDownloadAppClick = () => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = iosLink;
      } else {
        window.location.href = androidLink;
      }
    }
  };

  return (
    <div
      style={{
        minHeight: 70,
        height: "9vh",
        backgroundColor: "#272C46",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 12px",
      }}
    >
      <div
        className="download-link mobile-hide"
        style={{ visibility: "hidden" }}
      >
        <BrowserView style={{ display: "flex" }}>
          <div style={{ marginRight: 8 }}></div>
          <ReactStoreBadges platform={"ios"} locale={"en-us"} />
          <ReactStoreBadges platform={"ios"} locale={"en-us"} />
        </BrowserView>
      </div>
      <Logo width="112" />
      <div>
        <BrowserView style={{ display: "flex" }}>
          <div style={{ marginRight: 8 }}>
            <ReactStoreBadges
              platform={"android"}
              url={androidLink}
              locale={"en-us"}
            />
          </div>

          <ReactStoreBadges platform={"ios"} url={iosLink} locale={"en-us"} />
        </BrowserView>
        <MobileView>
          <div className="download-link" onClick={onDownloadAppClick}>
            download app
          </div>
        </MobileView>
      </div>
    </div>
  );
};
