import React from "react";
import { Header } from "../components/Header";
import styled from "styled-components";
import { PageBase } from "../components/PageBase";
import { isAndroid } from "react-device-detect";

import PromoPng from "../assets/promo.png";

const PageContainer = styled.div`
  max-width: 1200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const Heading = styled.div`
  max-width: 500px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 32px;
  /* or 139% */

  text-align: center;

  /* Blues/ 20 */

  color: #e9eaed;
`;

const Button = styled.div`
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: fit-content;
  padding: 0 20px;

  background: linear-gradient(270deg, #00b7b0 0%, #80dad8 100%);
  border-radius: 25px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.266667px;

  color: #191a29;
`;
const PromoImage = styled.img`
  width: 100%;
  max-width: 700px;
  min-width: 200px;
  height: auto;
  overflow-x: hidden;
  object-fit: contain;
  padding: 0 12px;
`;

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;

  min-height: 500px;
  width: 100%;
`;

export const MainPage = () => {
  const iosLink = "https://apps.apple.com/ee/app/athlon/id1490580477";
  const androidLink = "https://play.google.com/store/apps/details?id=chat.athlon";

  const onDownloadAppClick = () => {
    if (isAndroid) {
      window.location.href = androidLink;
    } else {
      window.location.href = iosLink;
    }
  };

  return (
    <PageBase>
      <Header />
      <PageContainer>
        <PromoContainer>
          <PromoImage src={PromoPng} />

          <Heading>
            Hey, we're still opening up, but you can already reserve your
            username.
          </Heading>
          <Button onClick={() => onDownloadAppClick()}>Download Athlon</Button>
        </PromoContainer>
      </PageContainer>
    </PageBase>
  );
};
