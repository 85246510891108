import React, { useState, useLayoutEffect } from "react";

const HEIGHT_RATIO = 3.375;

const getImage = (locale = "en-us", code = locale) => {
  return {
    ios: `https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1617062400&h=330bce3e8b7280b3c04d553541f2ad85`,
    android: `https://raw.github.com/yjb94/google-play-badge-svg/master/img/${code}_get.svg?sanitize=true`,
  };
};

export const ReactStoreBadges = ({
  url,
  defaultLocale = "en-us",
  platform,
  locale = (typeof navigator !== "undefined" && navigator.language) ||
    defaultLocale,
  width = 135,
  height = width / HEIGHT_RATIO,
  target = "_self",
}) => {
  let shortCode = (locale = locale.toLowerCase());
  const expeptionLocale = ["zh-cn", "zh-tw"];
  if (expeptionLocale.indexOf(locale) === -1) {
    shortCode = locale.split(/[_-]/)[0];
  }

  const [image, setImage] = useState(getImage(locale, shortCode));

  const setDefaultImage = () => {
    setImage(getImage(defaultLocale, shortCode));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setImage(getImage(locale, shortCode));
  }, [locale, shortCode]);

  return (
    <a
      style={{
        display: "inline-block",
        height: height,
        width: width,
      }}
      href={url}
      target={target}
    >
      <img
        src={image[platform]}
        alt="Download on the Store"
        style={{
          width: "100%",
          height: "100%",
        }}
        onError={setDefaultImage}
      />
    </a>
  );
};
