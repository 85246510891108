import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { VideoPlayer } from "../components/Player";
import { Header } from "../components/Header";
import { PageBase } from "../components/PageBase"

export const VideoPage = () => {
  axios.defaults.baseURL = "https://bff.athlon.chat";

  const [data, setData] = useState({});
  const params = useParams();

  useEffect(() => {
    const fetchContent = async () => {
      const result = await axios(`/content/v1/${params.contentId?.trim()}`);

      setData(result.data);
    };
    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data.path) return <div>loading... </div>;

  return (
    <PageBase style={{ backgroundColor: "#17192A", height: "100%" }}>
      <Header />

      <div className="video-container">
        <VideoPlayer link={data.path} channelName={data.chatTitle} />
      </div>
    </PageBase>
  );
};
