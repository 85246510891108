import React, { Component } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { BrowserView } from "react-device-detect";
import { ReactComponent as Muted } from "./icons/muted.svg";
import { ReactComponent as Volume } from "./icons/volume.svg";
import { ReactComponent as Play } from "./icons/play.svg";

export class VideoPlayer extends Component {
  constructor() {
    super();
    this.togglePlay = this.togglePlay.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleRangeUpdate = this.handleRangeUpdate.bind(this);
    this.scrub = this.scrub.bind(this);
    this.startMouseDown = this.startMouseDown.bind(this);
    this.endMouseDown = this.endMouseDown.bind(this);
    this.toggleMute = this.toggleMute.bind(this);

    this.state = {
      video: null,
      progress: "0%",
      currentTime: 0,
      timeLeft: 0,
      playbackRate: 1,
      volume: 1,
      isMouseDown: false,
      isMuted: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        video: this.refs.video,
      },
      () => {
        ["pause", "play"].forEach((event) => {
          this.state.video.addEventListener(event, () => {
            this.forceUpdate();
          });
        });
        this.state.video.addEventListener("timeupdate", this.handleProgress);
        this.state.video.addEventListener("loadeddata", () => {
          this.handleProgress();
        });
        this.state.video.addEventListener("contextmenu", (e) => {
          e.preventDefault();
        });
      }
    );
  }

  togglePlay() {
    const { video } = this.state;
    
    const method = video.paused ? "play" : "pause";
    video[method]();
  }

  toggleMute() {
    this.setState({
      isMuted: !this.state.isMuted,
    });
  }

  handleProgress() {
    const { video } = this.state;
    const percent = (video.currentTime / video.duration) * 100;
    const timeLeft =
      "-" +
      new Date((video.duration - video.currentTime) * 1000)
        .toISOString()
        .substr(15, 4);
    const currentTime = new Date(video.currentTime * 1000)
      .toISOString()
      .substr(15, 4);
    this.setState({
      progress: `${percent}%`,
      currentTime,
      timeLeft,
    });
  }

  handleRangeUpdate(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    // Todo: Check how to update state with Immutable JS
    // instead of using refs
    this.refs.video[name] = value;
  }

  scrub(e) {
    const scrubTime =
      (e.nativeEvent.offsetX / this.refs.video.clientWidth) *
      this.refs.video.duration;
    if (!isNaN(scrubTime)) {
      this.refs.video.currentTime = scrubTime;
    }
  }

  startMouseDown() {
    this.setState({
      isMouseDown: true,
    });
  }

  endMouseDown() {
    this.setState({
      isMouseDown: false,
    });
  }

  render() {
    const { progress, timeLeft, currentTime, video } = this.state;

    return (
      <div className="player">
        <video
          playsInline
          autoPlay
          controls={false}
          className="player__video viewer"
          ref="video"
          src={this.props.link}
          muted={this.state.isMuted}
          onClick={this.togglePlay}
        />
        <div className="player__top">
          <div className="player__watermark">
            <Logo />
            <div className="download-link">{this.props.channelName}</div>
          </div>
          <BrowserView>
            <div
              className="player__top__controls"
              onClick={() => this.toggleMute()}
            >
              {this.state.isMuted ? <Muted /> : <Volume />}
            </div>
          </BrowserView>
        </div>
       {video?.paused && (<div className="play__button" onClick={this.togglePlay}><Play /></div>)}
        <div className="player__controls">
          <div
            className="progress"
            onMouseDown={this.startMouseDown}
            onMouseUp={this.endMouseDown}
            onMouseLeave={this.endMouseDown}
            onMouseMove={(e) => this.state.isMouseDown && this.scrub(e)}
            onClick={this.scrub}
          >
            <div className="progress__filled" style={{ flexBasis: progress }} />
          </div>

          <div className="player__time_left">{currentTime}</div>
          <div style={{ width: "100%" }} />

          <div className="player__time_right">{timeLeft}</div>
        </div>
      </div>
    );
  }
}
